import Vue from 'vue';
import VueNotifications from 'vue-notifications';

export default {
  // getAccessServers(ctx, props = {}) {
  //   const config = props.query ?
  //     {
  //       params: {
  //         limit: ctx.filters.limit,
  //         offset: ctx.filters.offset,
  //         sort: `${ctx.filters.sort_order === -1 ? '-' : ''}${ctx.filters.sort_by}`
  //       }
  //     } :
  //     {};
  //   if (props.query && ctx.filters.search !== '') {
  //     config.params.search = ctx.filters.search;
  //   }
  //   // const config = {}
  //   ctx.$store.commit('portalAccessServersGetRequestExecutingBegin');
  //   Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/access_portal`, config).then(
  //     (response) => {
  //       if (response.status === 200 && response.data.status === 'success') {
  //         // // ctx.$store.commit('setPortalAdsList', response.data.data);
  //         // ctx.$store.commit('pushPortalAdsList', response.data.data);
  //
  //         //  ctx.accessServersListFromAPI = response.data.data;
  //
  //         let dataFormApi = [];
  //         // проверяем чтобы  с бэка пришел массив а не null или что нибудь еще
  //         if (Object.prototype.hasOwnProperty.call(response.data, 'data')) {
  //           if (
  //             response.data.data !== null &&
  //             response.data.data !== undefined &&
  //             response.data.data !== '' &&
  //             Array.isArray(response.data.data)
  //           ) {
  //             dataFormApi = response.data.data;
  //           }
  //         }
  //
  //         ctx.pushAccessServersList(dataFormApi);
  //         ctx.totalResult = response.data.total;
  //         if (ctx.accessServersListFromAPI.length >= response.data.total) {
  //           ctx.canLoadMore = false;
  //         } else {
  //           ctx.canLoadMore = true;
  //         }
  //       } else if (response.data.status === 'error') {
  //         // VueNotifications.error({ message: response.data.description });
  //         const errorText = this.extractErrorDescriptionFromResponse(response.data);
  //         VueNotifications.error({ message: errorText });
  //       }
  //       ctx.$store.commit('portalAccessServersGetRequestExecutingEnd');
  //     },
  //     (err) => {
  //       ctx.$store.commit('portalAccessServersGetRequestExecutingEnd');
  //       // VueNotifications.error({ message: err });
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //     }
  //   );
  // },
  getAccessServers(ctx, props = {}) {
    const config = props.query ?
      {
        params: {
          limit: ctx.filters.limit,
          offset: ctx.filters.offset,
          sort: `${ctx.filters.sort_order === -1 ? '-' : ''}${ctx.filters.sort_by}`,
          location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
        }
      } :
      {
        params: {
          location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
          with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
        }
      };
    if (props.query && ctx.filters.search !== '') {
      config.params.search = ctx.filters.search;
    }
    if (props.query && props.useLocationFilter) {
      if (ctx.filters?.location &&
        typeof ctx.filters.location === 'object' &&
        ctx.filters.location.data  &&
        typeof ctx.filters.location.data === 'object' ) {
        if (Object.keys(ctx.filters.location.data).length === 0) {
          config.params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
          config.params.with_childs = true;
        } else {
          config.params.location = ctx.filters.location.data.id;
          config.params.with_childs = ctx.filters.location.with_childs;
        }
      }
    }
    // const config = {}
    ctx.$store.commit('portalAccessServersGetRequestExecutingBegin');
    Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/access_portal`, config).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          // // ctx.$store.commit('setPortalAdsList', response.data.data);
          // ctx.$store.commit('pushPortalAdsList', response.data.data);

          //  ctx.accessServersListFromAPI = response.data.data;

          let dataFormApi = [];
          // проверяем чтобы  с бэка пришел массив а не null или что нибудь еще
          if (Object.prototype.hasOwnProperty.call(response.data, 'data')) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined &&
              response.data.data !== '' &&
              Array.isArray(response.data.data)
            ) {
              dataFormApi = response.data.data;
            }
          }

          ctx.pushAccessServersList(dataFormApi);
          ctx.totalResult = response.data.total;
          if (ctx.accessServersListFromAPI.length >= response.data.total) {
            ctx.canLoadMore = false;
          } else {
            ctx.canLoadMore = true;
          }
        } else if (response.data.status === 'error') {
          // VueNotifications.error({ message: response.data.description });
          const errorText = this.extractErrorDescriptionFromResponse(response.data);
          VueNotifications.error({ message: errorText });
        }
        ctx.$store.commit('portalAccessServersGetRequestExecutingEnd');
      },
      (err) => {
        ctx.$store.commit('portalAccessServersGetRequestExecutingEnd');
        // VueNotifications.error({ message: err });
        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
      }
    );
  },
  updateAccessServer(ctx, updatingAccessServer) {
    const updatingAccessServerForRequest = JSON.parse(JSON.stringify(updatingAccessServer));
    // updatingAccessServerForRequest.id = updatingAccessServerForRequest._id;
    // delete updatingAccessServerForRequest._id
    ctx.$store.commit('portalAccessServersUpdateRequestExecutingBegin');
    Vue.axios
      .put(`${RESTAPI || ''}/api/portal/admin/access_portal`, { request: [updatingAccessServerForRequest] })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({
              message: ctx.$t('portalAccessServers.notificationPortalAccessServerUpdatedSuccessfully')
            });
            // ctx.getWithQuery(ctx);
            // ctx.cancelEditMode();
            // console.log(response.data)
            let updatedServerFormResponse;
            // запихиваем в переменную updatedServerFormResponse ответ с изменненым объектом на PUT запрос
            if (
              response.data.hasOwnProperty('data') &&
              Array.isArray(response.data.data) &&
              response.data.data.length > 0
            ) {
              updatedServerFormResponse = response.data.data[0];
            }
            ctx.portalAccessServersUpdateListAfterUpdatingOnBackend(updatedServerFormResponse);
            ctx.closeModalAfterUpdateOrCreateAccessServer();
            // VueNotifications.error({ message: response.data.description });
          } else {
            // console.log(response.data)
            // VueNotifications.error({ message: response.data.description });
            const errorText = this.extractErrorDescriptionFromResponse(response.data);
            VueNotifications.error({ message: errorText });
          }
          ctx.$store.commit('portalAccessServersUpdateRequestExecutingEnd');
        },
        (err) => {
          // VueNotifications.error({ message: err });
          ctx.$store.commit('portalAccessServersUpdateRequestExecutingEnd');
          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }
        }
      );
  },
  createAccessServer(ctx, updatingAccessServer) {
    const updatingAccessServerForRequest = JSON.parse(JSON.stringify(updatingAccessServer));
    if (updatingAccessServerForRequest.hasOwnProperty('id')) {
      delete updatingAccessServerForRequest.id;
    }
    // updatingAccessServerForRequest.id = updatingAccessServerForRequest._id;
    // delete updatingAccessServerForRequest._id
    ctx.$store.commit('portalAccessServersCreateRequestExecutingBegin');
    Vue.axios
      .post(`${RESTAPI || ''}/api/portal/admin/access_portal`, { request: [updatingAccessServerForRequest] })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({
              message: ctx.$t('portalAccessServers.notificationPortalAccessServerCreatedSuccessfully')
            });
            // ctx.getWithQuery(ctx);
            // ctx.cancelEditMode();
            ctx.clearFiltersAndQuery();
            this.getAccessServers(ctx, { query: true });
            ctx.closeModalAfterUpdateOrCreateAccessServer();
            // VueNotifications.error({ message: response.data.description });
          } else {
            // console.log(response.data)
            // VueNotifications.error({ message: response.data.description });
            const errorText = this.extractErrorDescriptionFromResponse(response.data);
            VueNotifications.error({ message: errorText });
          }
          ctx.$store.commit('portalAccessServersCreateRequestExecutingEnd');
        },
        (err) => {
          // VueNotifications.error({ message: err });
          ctx.$store.commit('portalAccessServersCreateRequestExecutingEnd');
          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }
        }
      );
  },
  deleteAccessServer(ctx, updatingAccessServer) {
    const updatingAccessServerForRequest = JSON.parse(JSON.stringify(updatingAccessServer));
    const accessServerIdForDelete = updatingAccessServerForRequest.id;
    if (!accessServerIdForDelete || accessServerIdForDelete === undefined || accessServerIdForDelete === '') {
      return;
    }
    // updatingAccessServerForRequest.id = updatingAccessServerForRequest._id;
    // delete updatingAccessServerForRequest._id
    ctx.$store.commit('portalAccessServersDeleteRequestExecutingBegin');
    Vue.axios
      .delete(`${RESTAPI || ''}/api/portal/admin/access_portal`, {
        params: {
          ids: [accessServerIdForDelete]
        }
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({
              message: ctx.$t('portalAccessServers.notificationPortalAccessServerDeletedSuccessfully')
            });
            // ctx.getWithQuery(ctx);
            // ctx.cancelEditMode();
            ctx.clearQuery();
            this.getAccessServers(ctx, { query: true });
            ctx.closeModalAfterDeleteAccessServer();
            // VueNotifications.error({ message: response.data.description });
          } else {
            // console.log(response.data)
            // VueNotifications.error({ message: response.data.description });
            const errorText = this.extractErrorDescriptionFromResponse(response.data);
            VueNotifications.error({ message: errorText });
          }
          // console.log(response)
          ctx.$store.commit('portalAccessServersDeleteRequestExecutingEnd');
        },
        (err) => {
          // VueNotifications.error({ message: err });
          ctx.$store.commit('portalAccessServersDeleteRequestExecutingEnd');
          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }
        }
      );
  },

  extractErrorDescriptionFromResponse(responseData) {
    // console.log(responseData)
    let errorMsgText = '';
    if (responseData.hasOwnProperty('errors') && Array.isArray(responseData.errors) && responseData.errors.length > 0) {
      errorMsgText = responseData.errors[0];
    }
    return errorMsgText;
  }
};
